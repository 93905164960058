
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import firebase from "../../config/firebase";

let leads = RepositoryFactory.get("leads");

const state = {
  getLoader: false,
  id: "",
};

const getters = {
  getLoader: (state) => state.getLoader,
  profId: (state) => state.id,
};

const actions = {
  async postLead({ commit }, payload) {
    commit("setGetLoader", true);

    try {
      const response = await leads.postLead(payload);
      console.log("form==>", payload);

      const firestore = firebase.firestore();
      const collectionRef = firestore.collection("query");


      const docData = {
        firstname: payload.firstname,
        lastname: payload.lastname,
        email: payload.email,
        phone_number: payload.phone_number,
        description: payload.description,
        created_at: firebase.firestore.FieldValue.serverTimestamp(),

      };

      const docRef = await collectionRef.add(docData);
      commit("setGetLoader", false);
      commit("id", docRef.id);

      return {
        success: true,
        profileid: docRef.id,
      };
    } catch (error) {
      console.error("Firestore error:", error);

      return {
        success: false,
      };
    }
  },
};

const mutations = {
  setGetLoader(state, payload) {
    state.getLoader = payload;
  },
  id(state, payload) {
    state.id = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
