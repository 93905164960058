<template>
  <div class="hoe py-4">
    <v-container>
      <!-- <h2 class="pt-4"><b>Hoe bespaart tado°?</b></h2> -->
      <v-row class="my-5" id="services-section">
        <v-col cols="12" sm="6">
          <v-row class="align-center">
            <v-col cols="2">
              <img src="@/assets/location.png" width="100%" />
            </v-col>
            <v-col cols="10" class="px-0">
              <p class="mb-0">
                <span class="font-weight-bold">Hogere conversieratio's:</span>
                specifiek gericht op een bepaalde actie of doelgroep.
              </p>
            </v-col>
          </v-row>
          <v-row class="align-center">
            <v-col cols="2">
              <img src="@/assets/window.png" width="100%" />
            </v-col>
            <v-col cols="10" class="px-0">
              <p class="mb-0">
                <span class="font-weight-bold">Gerichte marketing: </span
                >mogelijkheid om specifieke segmenten van je publiek aan te
                spreken.
              </p>
            </v-col>
          </v-row>
          <v-row class="align-center">
            <v-col cols="2">
              <img src="@/assets/cloudy.png" width="100%" />
            </v-col>
            <v-col cols="10" class="px-0">
              <p class="mb-0">
                <span class="font-weight-bold"
                  >Merkbewustzijn en -positionering:</span
                >
                helpt bij het opbouwen en versterken van je merk.
              </p>
            </v-col>
          </v-row>
          <v-row class="align-center">
            <v-col cols="2">
              <img src="@/assets/schedule.png" width="100%" />
            </v-col>
            <v-col cols="10" class="px-0">
              <p class="mb-0">
                <span class="font-weight-bold">Verzamelen van leads:</span>

                effectief in het verzamelen van contactinformatie van potentiële
                klanten.
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6">
          <v-row class="align-center">
            <v-col cols="2">
              <img src="../assets/iconfile.png" width="100%" />
            </v-col>
            <v-col cols="10" class="px-0">
              <p class="mb-0">
                <span class="font-weight-bold">Minimalistisch design: </span
                >richt zich op één boodschap of aanbod, wat afleiding vermindert
              </p>
            </v-col>
          </v-row>
          <v-row class="align-center">
            <v-col cols="2">
              <img
                src="../assets/iconcomp.png"
                class="icon-computer"
                width="100%"
              />
            </v-col>
            <v-col cols="10" class="px-0">
              <p class="mb-0">
                <span class="font-weight-bold"
                  >Ondersteunt advertentiecampagnes:</span
                >
                ideaal voor gebruik met social ads en e- mailmarketingcampagnes.
              </p>
            </v-col>
          </v-row>
          <v-row class="align-center">
            <v-col cols="2">
              <img src="@/assets/remote_control.png" width="100%" />
            </v-col>
            <v-col cols="10" class="px-0">
              <p class="mb-0">
                <span class="font-weight-bold">Snelle implementatie: </span>kan
                snel ontworpen en gelanceerd worden om in te spelen op
                marktbehoeften
              </p>
            </v-col>
          </v-row>

          <v-row class="align-center d-block d-sm-none d-md-block">
            <v-btn
              rounded
              color="white"
              light
              class="px-8 mt-9 mx-3 d-none d-md-block"
              @click="scrollToTop"
            >
              <b>Persoonlijke offerte aanvragen </b>
              <img src="@/assets/btn_arrow_warning.png" class="ml-8" />
            </v-btn>
            <v-btn
              rounded
              color="white"
              light
              large
              class="px-8 mt-9 mx-auto d-block d-md-none"
              @click="scrollToTop"
            >
              <b style="font-size: 8px">Persoonlijke offerte aanvragen </b>
              <img src="@/assets/btn_arrow_warning.png" class="ml-8" />
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "HeroSection",
  methods: {
    scrollToTop() {
      const formSection = document.getElementById("form-section");
      if (formSection) {
        formSection.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-computer {
  height: 70px;
  width: 80px;
  margin-left: -18px;
  @media only screen and (max-width: 598.9px) {
    height: 50px;
    width: 60px;
    margin-left: -15px;
  }
  @media only screen and (max-width: 424.9px) {
    height: 40px;
    width: 50px;
    margin-left: -10px;
  }
}
.icon {
  position: relative;
  top: -52px;
  height: 25px;
  width: 60px;
}
.hoe {
  background: #00b969;
  color: white !important;
  & h2 {
    font-family: "Freizeit-Regular" !important;
  }
  & p {
    font-family: "Freizeit-Regular" !important;
  }
}
</style>
