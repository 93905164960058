var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"formSection",attrs:{"id":"form-section"}},[_c('v-container',{staticClass:"py-0 py-md-8"},[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"pl-3 pl-sm-0 pt-16 pt-sm-0",attrs:{"cols":"12","sm":"6"}},[(!this.submited)?_c('v-container',{staticClass:"mx-auto pa-6 py-10 px-6 rounded-xl elevation-6"},[_c('h2',[_vm._v("Persoonlijke offerte aanvragen")]),_c('p',[_c('b',[_vm._v(" Op zoek naar een landingspagina die converteert? Vul nu het contactformulier in en ontvang binnen één werkdag een persoonlijke offerte die perfect aansluit bij jouw bedrijfsbehoeften")])]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"Voornaam","rules":{
                  required: true,
                  regex: /^([a-zA-Z ]){1,30}$/,
                }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Voornaam","required":"","flat":"","rounded":"","filled":"","dense":""},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Achternaam","rules":{
                  required: true,
                  regex: /^([a-zA-Z ]){1,30}$/,
                }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Achternaam","required":"","flat":"","rounded":"","filled":"","dense":""},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"E-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"E-mail","required":"","flat":"","rounded":"","filled":"","dense":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Telefoonnummer","rules":{
                  required: true,
                  digits: 10,
                  regex: /^0\d{9}$/,
                }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Telefoonnummer","maxLength":"10","required":"","flat":"","rounded":"","filled":"","dense":""},model:{value:(_vm.form.phone_number),callback:function ($$v) {_vm.$set(_vm.form, "phone_number", $$v)},expression:"form.phone_number"}})]}}],null,true)}),_c('v-textarea',{attrs:{"name":"input-7-1","filled":"","label":"Uitleg","auto-grow":"","value":""},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"py-6",attrs:{"rounded":"","color":"success","type":"submit","light":"","block":"","disabled":invalid}},[(_vm.getLoader)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white"}}):_c('span',{staticClass:"d-flex"},[_vm._v(" Aanvraag bevestigen "),_c('img',{staticClass:"ml-8",attrs:{"src":require("@/assets/btn_arrow_white.png")}})])],1),_c('div',{staticClass:"formBottom__text"})],1)],1)]}}],null,false,597878718)})],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }