<template>
  <v-app>
    <!-- <v-container class="d-flex justify-space-between navbar"> -->
    <!-- <img src="@/assets/nav_trustpilot.png" class="navbar__logo--right" /> -->
    <!-- <div class="flex">
        <p>services</p>
        <p>contact us</p>
        <p>our client</p>
      </div> -->
    <!-- <ul> -->
    <!-- <li>Home</li>
  <li><a href="#news">News</a></li>
  <li><a href="#contact">Contact</a></li>
  <li><a href="#about">About</a></li>
</ul> -->
    <v-app-bar app class="height_navbar p-10">
      <!-- <v-app-bar-nav-icon @click="toggleDrawer"></v-app-bar-nav-icon> -->
      <!-- <v-toolbar-title>
      <span class="font-weight-bold">Your Logo</span>
    </v-toolbar-title> -->
      <img
        src="./assets/jbb-logo.png"
        class="cursor--pointer"
        @click="scrollToTop"
        height="100px"
      />
      <v-spacer></v-spacer>
      <div class="d-none d-sm-flex">
        <v-btn text @click="scrollToServices">services</v-btn>
        <v-btn text @click="scrollToTop">contact</v-btn>
        <v-btn text @click="scrollToClient">Reviews</v-btn>
      </div>
      <v-app-bar-nav-icon
        class="d-sm-none d-flex"
        @click="toggleDrawer"
      ></v-app-bar-nav-icon>
      <v-navigation-drawer
        class="drawer d-flex d-sm-none"
        app
        v-model="drawer"
        right
      >
        <v-list>
          <v-list-item @click="scrollToServices">Services</v-list-item>
          <v-list-item @click="scrollToTop">Contact</v-list-item>
          <v-list-item @click="scrollToClient">Reviews</v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-app-bar>
    <!-- </v-container> -->
    <v-main class="main">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    scrollToTop() {
      const formSection = document.getElementById("form-section");
      if (formSection) {
        formSection.scrollIntoView({ behavior: "smooth" });
      }
    },
    scrollToTop() {
      const formSection = document.getElementById("form-section");
      if (formSection) {
        formSection.scrollIntoView({ behavior: "smooth" });
      }
    },
    scrollToServices() {
      const formSection = document.getElementById("services-section");
      if (formSection) {
        formSection.scrollIntoView({ behavior: "smooth" });
      }
    },
    scrollToClient() {
      const formSection = document.getElementById("client-section");
      if (formSection) {
        formSection.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>
<style lang="scss">
html.overflow-y-hidden {
  overflow-y: auto !important;
}
.drawer {
  width: 200px !important;
  height: 30vh !important;
}

.main {
  padding: 0px 0px 0px !important;
  @media only screen and (max-width: 598.9px) {
    padding: 0px 0px 0px !important;
  }
}

.v-app-bar.v-app-bar--fixed {
  position: relative !important;
}
.v-overlay--active {
  pointer-events: none !important;
}

.v-overlay__scrim {
  background-color: transparent !important;
  border-color: transparent !important;
}
.v-toolbar__content {
  margin: 10px;
  padding: 4px 100px !important;
  @media only screen and (max-width: 598.9px) {
    padding: 34px 10px !important;
  }
}
.height_navbar {
  height: 85px !important;
}
$primary-color: #00b969;
@font-face {
  font-family: "Freizeit-Medium";
  src: url(./assets/fontfamily/Freizeit-Medium.otf);
}
@font-face {
  font-family: "Freizeit-Regular";
  src: url(./assets/fontfamily/Freizeit-Regular.otf);
}
@font-face {
  font-family: "Freizeit-RegularSlanted";
  src: url(./assets/fontfamily/Freizeit-RegularSlanted.otf);
}
@font-face {
  font-family: "Freizeit-Bold";
  src: url(./assets/fontfamily/Freizeit-Bold.otf);
}
@font-face {
  font-family: "Freizeit-ExtraBold";
  src: url(./assets/fontfamily/Freizeit-ExtraBold.otf);
}
* {
  font-family: "Freizeit-Medium", sans-serif;
  .font--bold {
    font-family: "Freizeit-Bold" !important;
  }
  .font--extraBold {
    font-family: "Freizeit-ExtraBold" !important;
  }
  .font--Medium {
    font-family: "Freizeit-Medium" !important;
  }
  .font--Regular {
    font-family: "Freizeit-Regular" !important;
  }
  .font--RegularSlanted {
    font-family: "Freizeit-RegularSlanted" !important;
  }
}
.container {
  @media (min-width: 1264px) {
    max-width: 1025px !important;
  }
}
.v-btn__content {
  @media only screen and (max-width: 370px) {
    font-size: 10px;
  }
}
.navbar {
  &__logo--left {
    width: 310px;
    @media only screen and (max-width: 767.98px) {
      width: 200px;
    }
  }
  &__logo--right {
    width: 150px;
    @media only screen and (max-width: 767.98px) {
      width: 90px;
    }
  }
}
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
.cursor--pointer {
  cursor: pointer;
}
</style>
