// import { initializeApp } from "firebase/app";
// // import firebase from 'firebase/app';
// import 'firebase/firestore';
// const firebaseConfig = {
//     apiKey: "AIzaSyDUxLc33tVVmpMOvqYZ-g2VAbGO6gk2A10",
//     authDomain: "jbb-platform.firebaseapp.com",
//     projectId: "jbb-platform",
//     storageBucket: "jbb-platform.appspot.com",
//     messagingSenderId: "524256533477",
//     appId: "1:524256533477:web:15323ae55bc462d1bded0c"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// // // const firebaseApp = firebase.initializeApp(firebaseConfig);
// // firebase.initializeApp(firebaseConfig);
// const firestore = firebaseApp.firestore();
// export { firestore, firebase };

import firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDUxLc33tVVmpMOvqYZ-g2VAbGO6gk2A10",
    authDomain: "jbb-platform.firebaseapp.com",
    projectId: "jbb-platform",
    storageBucket: "jbb-platform.appspot.com",
    messagingSenderId: "524256533477",
    appId: "1:524256533477:web:15323ae55bc462d1bded0c"
};

firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();
export default firebase;
