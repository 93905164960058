<template>
  <div class="formSection" id="form-section">
    <v-container class="py-0 py-md-8">
      <v-row class="d-flex justify-center">
        <v-col cols="12" sm="6" class="pl-3 pl-sm-0 pt-16 pt-sm-0">
          <v-container
            class="mx-auto pa-6 py-10 px-6 rounded-xl elevation-6"
            v-if="!this.submited"
          >
            <h2>Persoonlijke offerte aanvragen</h2>
            <p>
              <b>
                Op zoek naar een landingspagina die converteert? Vul nu het
                contactformulier in en ontvang binnen één werkdag een
                persoonlijke offerte die perfect aansluit bij jouw
                bedrijfsbehoeften</b
              >
            </p>
            <!--  -->
            <validation-observer ref="observer" v-slot="{ invalid }">
              <form @submit.prevent="submit">
                <validation-provider
                  v-slot="{ errors }"
                  name="Voornaam"
                  :rules="{
                    required: true,
                    regex: /^([a-zA-Z ]){1,30}$/,
                  }"
                >
                  <v-text-field
                    v-model="form.firstname"
                    :error-messages="errors"
                    label="Voornaam"
                    required
                    flat
                    rounded
                    filled
                    dense
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="Achternaam"
                  :rules="{
                    required: true,
                    regex: /^([a-zA-Z ]){1,30}$/,
                  }"
                >
                  <v-text-field
                    v-model="form.lastname"
                    :error-messages="errors"
                    label="Achternaam"
                    required
                    flat
                    rounded
                    filled
                    dense
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="E-mail"
                  rules="required|email"
                >
                  <v-text-field
                    v-model="form.email"
                    :error-messages="errors"
                    label="E-mail"
                    required
                    flat
                    rounded
                    filled
                    dense
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="Telefoonnummer"
                  :rules="{
                    required: true,
                    digits: 10,
                    regex: /^0\d{9}$/,
                  }"
                >
                  <v-text-field
                    v-model="form.phone_number"
                    :error-messages="errors"
                    label="Telefoonnummer"
                    maxLength="10"
                    required
                    flat
                    rounded
                    filled
                    dense
                  ></v-text-field>
                </validation-provider>
                <v-textarea
                  v-model="form.description"
                  name="input-7-1"
                  filled
                  label="Uitleg"
                  auto-grow
                  value=""
                ></v-textarea>

                <div class="text-center">
                  <v-btn
                    rounded
                    color="success"
                    type="submit"
                    light
                    class="py-6"
                    block
                    :disabled="invalid"
                  >
                    <v-progress-circular
                      v-if="getLoader"
                      indeterminate
                      color="white"
                    ></v-progress-circular>
                    <span class="d-flex" v-else>
                      Aanvraag bevestigen
                      <img src="@/assets/btn_arrow_white.png" class="ml-8" />
                    </span>
                  </v-btn>
                  <div class="formBottom__text">
                    <!-- <small>
                      Door op versturen te klikken ga je akkoord met de
                      <span @click="algemeneVoorwaardenModal = true"
                        >algemene voorwaarden</span
                      >.
                    </small> -->
                  </div>
                </div>
              </form>
            </validation-observer>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import arrow from "@/assets/form_arrow.png";
import leftImg from "@/assets/hero_image2.png";
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import FooterSection from "./FooterSection.vue";
setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
  ...required,
  message: "obliged",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
  ...regex,
  message: "{_value_} : ongeldig formaat",
});

extend("email", {
  ...email,
  message: "Email moet geldig zijn",
});
export default {
  ...mapActions(["postLead"]),
  name: "FormSection",
  components: {
    FooterSection,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      arrow,
      leftImg,
      emailConfirm: true,
      algemeneVoorwaardenModal: false,
      submited: false,
      form: {
        description: "",
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
      },
    };
  },
  methods: {
    async submit() {
      let resp = await this.postLead(this.form);
      this.$router.push("thankyou");
      console.log("form=>", this.form);
      if (resp.success == true) {
        this.submited = true;
      }
    },
  },
  watch: {
    submited() {
      if (this.submited) {
        let useScript = document.createElement("script");
        useScript.setAttribute(
          !(function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
              n.callMethod
                ? n.callMethod.apply(n, arguments)
                : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = "2.0";
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
          })(
            window,
            document,
            "script",
            "https://connect.facebook.net/en_US/fbevents.js"
          ),
          fbq("init", "886236645362028"),
          fbq("track", "PageView"),
          fbq("track", "Lead")
        );
        document.head.appendChild(useScript);

        this.noscriptHtml =
          '<noscript><img height="1" width="1" src="https://www.facebook.com/tr?id=886236645362028&ev=PageView&noscript=1"</noscript>';
        document.title = "Bedankt";
      } else {
        document.title = "Tado +";
      }
    },
  },
  created() {
    window.scrollTo(0, 0);

    this.form.publisher_id = this.$route.query.site;
    this.form.site_subid = this.$route.query.oa_id;
  },
};
</script>

<style lang="scss" scoped>
.formSection {
  border-top: 1px solid #d6f4e7;
}
.contentLeft {
  background: #ffedcc;
  height: 435px;
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #00b969 !important;
  color: white !important;
}
.submited {
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.formBottom__text {
  text-align: left;
  font-size: 13px;
  margin-top: 10px;
  & span {
    cursor: pointer;
    font-weight: bold;
  }
}
</style>
