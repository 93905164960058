<template>
  <div class="pt-4 startSection">
    <v-container>
      <v-row class="align-center">
        <v-col cols="12" md="6">
          <h1 class="white--text">
            <b> Realiseer vandaag jouw professionele landingspagina!</b>
          </h1>
          <v-btn
            rounded
            color="success"
            light
            class="px-8 mt-5 d-none d-md-block"
            @click="scrollToTop"
          >
            Persoonlijke offerte aanvragen
            <img src="@/assets/btn_arrow_white.png" class="ml-8" />
          </v-btn>
          <v-btn
            rounded
            color="success"
            light
            large
            class="px-8 mt-5 mx-auto d-block d-md-none"
            @click="scrollToTop"
          >
            <b style="font-size: 8px"> Persoonlijke offerte aanvragen</b>
            <img src="@/assets/btn_arrow_white.png" class="ml-8" />
          </v-btn>
        </v-col>
        <v-col cols="12" md="6">
          <img src="../assets/bottom.png" width="100%" />
        </v-col>
      </v-row>
    </v-container>
    <footer-section />
  </div>
</template>

<script>
import FooterSection from "./FooterSection.vue";

export default {
  name: "StartSection",
  components: { FooterSection },
  methods: {
    scrollToTop() {
      const formSection = document.getElementById("form-section");
      if (formSection) {
        formSection.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.startSection {
  background: #f9a300;
  & h1 {
    font-family: "Freizeit-Regular", sans-serif;
  }
}
</style>
