<template>
  <div class="home">
    <div>
      <hero-section />
      <hoe-section />
      <ErSection />
      <start-section />
    </div>
  </div>
</template>

<script>
import HeroSection from "../components/HeroSection.vue";
import HoeSection from "../components/HoeSection.vue";
import ErSection from "../components/ErSection.vue";
import StartSection from "../components/StartSection.vue";
import FormSection from "../components/FormSection.vue";

export default {
  name: "Home",
  components: {
    HeroSection,
    HoeSection,
    ErSection,
    StartSection,
    FormSection,
  },

  //
  mounted() {
    let useScript = document.createElement("script");
    useScript.setAttribute(
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      ),
      fbq("init", "886236645362028"),
      fbq("track", "PageView")
    );
    document.head.appendChild(useScript);
  },
  created() {
    this.noscriptHtml =
      '<noscript><img height="1" width="1" src="https://www.facebook.com/tr?id=886236645362028&ev=PageView&noscript=1"/></noscript>';
  },
};
</script>
