import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters(["getLoader", "profId",]),
  },
  methods: {
    ...mapActions(["postLead",]),
  },
};
