<template>
  <div>
    <v-container
      class="mx-auto pa-6 py-10 px-6 rounded-xl elevation-6 submited"
    >
      <h3>
        Thank you for your request. We will contact you by telephone as soon as
        possible to inform you about how you can best save!
      </h3>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Thankyou",
};
</script>

<style></style>
