<template>
  <div class="hero py-2 py-md-5">
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" class="heading">
          <h1 class="font--bold mb-10 text-center text-md-start">
            Jouw eigen landingspagina. Verhoog je leads vandaag!
          </h1>
          <v-btn
            rounded
            color="warning"
            light
            large
            class="px-8 mt-5 d-none d-md-block"
            @click="scrollToTop"
          >
            <b>Persoonlijke offerte aanvragen </b>
            <img src="@/assets/btn_arrow_white.png" class="ml-3" />
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" class="py-0 py-md-3 bottomImg">
          <img :src="hero" width="100%" class="mt-0 mt-lg-3 ml-0 ml-lg-4" />
          <v-btn
            rounded
            color="warning"
            light
            class="px-8 d-block my-2 d-md-none mx-auto"
            @click="scrollToTop"
          >
            <b style="font-size: 8px">Persoonlijke offerte aanvragen </b>
            <img src="@/assets/btn_arrow_white.png" class="ml-3" />
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import hero from "@/assets/bb_header.png";
export default {
  name: "HeroSection",
  data() {
    return {
      hero,
    };
  },
  methods: {
    scrollToTop() {
      const formSection = document.getElementById("form-section");
      if (formSection) {
        formSection.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color: #00b969;
.hero {
  background: #f6f6f6;
  height: 490px;
  @media only screen and (max-width: 1263.98px) {
    height: auto;
  }

  & .heading {
    display: flex;
    flex-direction: column;
    align-items: start !important;
    justify-content: center !important;

    & h1 {
      font-family: "Freizeit-Bold";
      @media only screen and (max-width: 767.98px) {
        font-size: 20px;
      }
    }
    & .headingTip {
      color: $primary-color;
      font-family: "Freizeit-ExtraBold" !important;
    }
    & img {
      position: relative;
      top: 0px;
      right: 0px;
    }
  }
  & .list {
    & p {
      @media only screen and (max-width: 767.98px) {
        font-size: 14px;
      }
    }
  }
  & .bottomImg {
    text-align: center;
    @media only screen and (max-width: 767.98px) {
      margin-top: -25px;
    }
    & img {
      @media only screen and (max-width: 767.98px) {
        width: 70% !important;
      }
    }
  }
}
</style>
