<template>
  <div class="white py-5 mt-4 text-center">
    <small
      >© {{ new Date().getFullYear() }} LES. Alle rechten voorbehouden.
    </small>
  </div>
</template>

<script>
export default {
  name: "FooterSection",
  data() {
    return {
      privacybeleid: false,
    };
  },
};
</script>

<style lang="scss" scoped>
span {
  cursor: pointer;
}
</style>
