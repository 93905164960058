<template>
  <div class="py-5 erSection">
    <v-container class="my-2 my-md-5">
      <v-row class="align-center">
        <v-col cols="12" sm="6">
          <img src="@/assets/ErWerken.png" width="100%" />
        </v-col>
        <v-col cols="12" sm="6">
          <h3 class="my-3">Hoe het werkt</h3>
          <p>
            met een conversiegerichte pagina. Allereerst bespreken we je
            doelstellingen en het beoogde publiek. Vervolgens ontwerpen we een
            op maat gemaakte landingspagina die deze elementen samenbrengt met
            een aantrekkelijk design en een krachtige call-to-action. Na jouw
            goedkeuring gaat de landingspagina live, klaar om bezoekers om te
            zetten in leads.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <!-- <img src="@/assets/image.png" width="100%" /> -->
      <form-section />
    </v-container>
    <div></div>
    <v-container class="trustPilot" id="client-section">
      <div class="text-center">
        <img src="../assets/trustpilot.png" />
        <h1>Onze klanten beoordelen ons met een 4.9 / 5.0</h1>
      </div>
      <v-row class="my-5">
        <v-col cols="12" sm="6" md="4">
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-container
                :class="`elevation-${hover ? 24 : 6}`"
                class="mx-auto pa-6 transition-swing py-10 px-6 rounded-xl"
              >
                <img src="../assets/review_active.png" class="my-3" />
                <br />
                <small
                  >Als ondernemer weet ik hoe cruciaal een sterke online
                  aanwezigheid is. De pagina die Luuk voor mij heeft ontworpen
                  is niet alleen functioneel, maar ontvangt ook constant
                  positieve feedback op zijn esthetiek en mooie design
                </small>
                <p>
                  <small><b>Dhr. Barendse,</b>Vastgoed ondernemer</small>
                </p>
              </v-container>
            </template>
          </v-hover>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-container
                :class="`elevation-${hover ? 24 : 6}`"
                class="mx-auto pa-6 transition-swing py-10 px-6 rounded-xl"
              >
                <img src="../assets/review.png" class="my-3" />
                <br />
                <small
                  >De samenwerking met Luuk voor onze landingspagina was
                  uitstekend. Het design is mooi, maar ook de levering binnen
                  een week overtrof onze verwachtingen.
                </small>
                <p>
                  <small
                    ><b>Patrick Roebersen,</b>Teamlead & Partner Manager at
                    Sales Marketing Coach BV, Sales Marketing Group BV &
                    Potentials.nl</small
                  >
                </p>
              </v-container>
            </template>
          </v-hover>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-container
                :class="`elevation-${hover ? 24 : 6}`"
                class="mx-auto pa-6 transition-swing py-10 px-6 rounded-xl"
              >
                <img src="../assets/review.png" class="my-3" />
                <br />
                <small>
                  Dankzij mijn nieuwe landingspagina kan ik nu mijn portfolio
                  moeiteloos en snel delen, wat bijdraagt aan krachtigere en
                  overtuigendere gesprekken met potentiële opdrachtgevers!
                </small>
                <p>
                  <small
                    ><b>Rick Bergfeld,</b> Copywriting freelancer/Interimmer
                  </small>
                </p>
              </v-container>
            </template>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FormSection from "./FormSection.vue";
export default {
  components: { FormSection },
  name: "ErSection",
};
</script>

<style lang="scss" scoped>
$primary-color: #00b969;

.erSection {
  & h3 {
    color: $primary-color;
  }
  & h1 {
    @media only screen and (max-width: 767.98px) {
      font-size: 20px;
    }
  }
}
</style>
