import axios from "axios";
const baseDomain = "https://us-central1-jbb-platform.cloudfunctions.net/sendEmail";
const baseURL = `${baseDomain}/`;
let axiosObj;
axiosObj = axios.create({
  baseURL,
  // headers: {
  //   Authorization: `Basic MTg1OmFiODIyMWQ0YTMxNzBkODk1NDI4ODA0NTlhYmY3OTgxN2FlMzY3YzI=`,
  // },
});
export default axiosObj;
